<template>
	<div id="miniServiceList">
		<page-service-list :is-shop="0"></page-service-list>
	</div>
</template>
<script>
	import pageServiceList from '@/components/layout/goods/page-service-list.vue'
	export default{
		components:{
			pageServiceList
		},
		data(){
			return{
			}
		},
		mounted() {
		},
		methods:{
			
		}
	}
</script>

<style>
</style>
